import Vue from "vue"
import Vuex from "vuex"

import auth from "./modules/auth"
import plan from "./modules/plan"
import contact from "./modules/contact"

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        auth,
        plan,
        contact,
    },
})

export default store
