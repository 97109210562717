<template>
    <div>
        <div class="top-section">
            <div class="container">
                <div class="row middel-flex">
                    <div class="col-sm-6">
                        <div class="socil-icon">
                            <ul class="wow bounceInRight">
                                <li>
                                    <a href="https://www.facebook.com/scholenberg.3" target="_blank"
                                        ><i class="fa fa-facebook-f"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/Scholenberg1" target="_blank"
                                        ><i class="fa fa-twitter-square"></i
                                    ></a>
                                </li>

                                <li>
                                    <a
                                        href="https://www.linkedin.com/in/scholenberg-consulting-2567181b0/"
                                        target="_blank"
                                        ><i class="fa fa-linkedin"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.instagram.com/scholenberg123/"
                                        target="_blank"
                                        ><i class="fa fa-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.youtube.com/channel/UCT1b9Xy36suK8GNxe2CrPbQ"
                                        target="_blank"
                                        ><i class="fa fa-youtube-play"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <div class="log-in-btn">
                            <ul class="nav navbar-nav flex-row float-right">
                                <li class="nav-item">
                                    <div class="dropdown" v-if="!isLogged">
                                        <button
                                            class="btn btn-sm register-btn dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <i class="fa fa-edit"></i>
                                            <span class="divider">|</span>
                                            Register
                                        </button>

                                        <div
                                            class="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton"
                                        >
                                            <router-link class="dropdown-item" to="/register"
                                                >Buyer/Seller</router-link
                                            >
                                            <router-link
                                                class="dropdown-item"
                                                to="/materialregister"
                                                >Material Inspector</router-link
                                            >
                                            <!-- <router-link class="dropdown-item" to="/"
                                                >Service Providers</router-link
                                            > -->
                                            <!--<router-link class="dropdown-item" to="./applyforjob">Apply For Job</router-link>-->
                                        </div>
                                    </div>
                                </li>

                                <li class="nav-item">
                                    <router-link
                                        class="register-btn log-btn"
                                        v-if="!isLogged"
                                        to="/login"
                                        ><i class="fa fa-sign-in"></i>|
                                        <span>Login</span></router-link
                                    >
                                </li>

                                <li class="nav-item">
                                    <router-link
                                        v-if="isLogged"
                                        class="nav-link pr-3"
                                        to="/dashboard"
                                    >
                                        <!-- Welcome, {{ user.name }} -->
                                        Dashboard
                                    </router-link>
                                </li>

                                <li class="nav-item">
                                    <a
                                        href="javascript:void(0);"
                                        v-if="isLogged"
                                        @click="logout"
                                        class="nav-link pr-3"
                                        >Logout</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="menu-bar">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <nav class="navbar navbar-expand-lg navbar-light">
                            <router-link class="navbar-brand" to="/"
                                ><img src="/images/mepcindia.png"
                            /></router-link>
                            <button
                                class="navbar-toggler"
                                id="navbar-toggle-btn"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul class="navbar-nav ml-auto">
                                    <li class="nav-item">
                                        <router-link class="nav-link" to="/">Home</router-link>
                                    </li>
                                    <!-- <li class="nav-item">
                                        <router-link class="nav-link" to="about">About</router-link>
                                    </li> -->
                                    <li class="nav-item">
                                        <router-link
                                            class="nav-link"
                                            to="/pricing"
                                            v-if="isLogged && currentPlan"
                                        >
                                            My Plans
                                        </router-link>
                                        <router-link class="nav-link" to="/pricing" v-else>
                                            Choose a Plan
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link class="nav-link" to="/vacancies"
                                            >Recruitment</router-link
                                        >
                                    </li>
                                    <!--<li class="nav-item">
                <a class="nav-link" href="#">Surplus</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">Shortage</a>
              </li>-->
                                    <li class="nav-item">
                                        <router-link
                                            class="nav-link"
                                            :to="{ name: 'directory_listing' }"
                                            >Directories</router-link
                                        >
                                    </li>
                                    <li class="nav-item"></li>
                                    <li>
                                        <a
                                            class="nav-link"
                                            href="https://www.sbgblv.com/page/library"
                                            target="_blank"
                                            >Trainings</a
                                        >
                                        <!--<a class="nav-link" href="#">Trainings</a>-->
                                    </li>
                                    <li class="nav-item">
                                        <router-link class="nav-link" to="/categories"
                                            >Categories</router-link
                                        >
                                    </li>
                                    <!-- <li class="nav-item">
                                        <router-link class="nav-link" to="/industry_insight"
                                            >Industry Insight</router-link
                                        >
                                    </li> -->
                                    <li class="nav-item">
                                        <router-link class="nav-link" :to="{ name: 'blogs' }"
                                            >Blogs</router-link
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <router-link class="nav-link" to="/contact"
                                            >Contact</router-link
                                        >
                                        <!--<a class="nav-link" href="#">Contact</a>-->
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            v-if="!isMaterialTester"
                                            class="nav-link post-btn px-2"
                                            href="javascript:void(0)"
                                            @click="navigateToPostSurplus"
                                            ><i class="fa fa-plus-circle"></i>
                                            {{
                                                navigatingToPostSurplus
                                                    ? "Please wait..."
                                                    : "Post your Surplus"
                                            }}</a
                                        >
                                        <!--<a class="nav-link post-btn" href="#"><i class="fa fa-plus-circle"></i> Post your Surplus</a>-->
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import request from "../../apis/request"
import { mapActions, mapGetters, mapState } from "vuex"

export default {
    data() {
        return {
            navigatingToPostSurplus: false,
        }
    },
    async mounted() {
        try {
            await this.fetchUser()
        } catch (err) {
            console.log("error ", err)
        }

        // auto collapse navbar on click, only for devices with screen size smaller than 768px

        const items = document.querySelectorAll(".menu-bar .navbar-nav>li>a")
        const btn = document.querySelector("#navbar-toggle-btn")
        for (let item of items) {
            item.onclick = function() {
                if (window.innerWidth < 768) {
                    document.querySelector("#navbar-toggle-btn").click()
                }
            }
        }
    },
    computed: {
        ...mapState("auth", {
            user: "user",
            authenticated: "authenticated",
            isLogged: "authenticated",
        }),
        ...mapState("plan", {
            currentPlan: "currentPlan",
        }),
        ...mapGetters("auth", {
            isMaterialTester: "isMaterialTester",
        }),
    },

    methods: {
        ...mapActions("auth", {
            fetchUser: "fetchUser",
            logoutUser: "logout",
        }),
        async logout() {
            await this.logoutUser()
            window.location.href = this.$router.resolve({ name: "login" }).href
        },
        async navigateToPostSurplus() {
            if (!this.authenticated) {
                return this.$toasted.error("Please login to post a surplus product.")
            }
            if (this.navigatingToPostSurplus) return
            this.navigatingToPostSurplus = true
            try {
                await request.get("/surplus/check-allowed")
                this.$router.push({ name: "surplus" })
            } catch (err) {
                const message = err.response && err.response.data && err.response.data.message
                this.$toasted.error(message || "You are not allowed to post surplus.")
            }
            this.navigatingToPostSurplus = false
        },
    },
}
</script>

<style></style>
