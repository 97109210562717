import { KEYS, getToken, setToken, unsetToken, getItem, setItem } from "../localStore"
import request from "../../apis/request"
import Vue from "vue"

console.log("authjs mounted")
const auth = {
    namespaced: true,
    state: {
        user: getItem(KEYS.USER) ? JSON.parse(getItem(KEYS.USER)) : "",
        authenticated: getItem(KEYS.USER) ? true : false,
    },
    getters: {
        isMaterialTester(state) {
            if (state.user) {
                return Number(state.user && state.user.role_id) === 3
            }
            return false
        },
    },
    mutations: {
        setUser(state, user) {
            state.user = user
            state.authenticated = true
            setItem(KEYS.USER, JSON.stringify(user))
        },
        unsetUser(state, data) {
            console.log("unset user running")
            state.user = ""
            state.authenticated = false
            setItem(KEYS.USER, "")
            unsetToken()
            if (data && data.reload) {
                window.location.href = "/login"
            }
        },
    },
    actions: {
        async fetchUser(context) {
            if (getToken()) {
                try {
                    const res = await request.get("/user")
                    const user = res.data
                    // console.log("user fetched in vuex", user)
                    if (user) {
                        context.commit("setUser", user)
                    } else {
                        context.commit("unsetUser", { reload: true })
                    }
                } catch (error) {
                    context.commit("unsetUser", { reload: true })
                    console.log("error in fetching user", error)
                }
            } else {
                // remove the token
                context.commit("unsetUser")
            }
        },
        async login(context, data) {
            const { email, password } = data
            try {
                const res = await request.post("/login", {
                    email,
                    password,
                })
                console.log("response of login", res)
                const token = res.data.access_token
                setToken(token)
                await context.dispatch("fetchUser")
            } catch (err) {
                Vue.toasted.error(
                    (err.response.data && err.response.data.message) || "Could not login."
                )
                throw err
            }
        },
        async logout(context) {
            // const res = await request.post("/logout")
            context.commit("unsetUser")
        },
    },
}

export default auth
