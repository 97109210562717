<template>
    <div>
        <div class="footer-section">
            <div class="container">
                <div class="row">
                    <div class="col-sm-3">
                        <h3>GENERAL</h3>
                        <ul>
                            <li>
                                <!-- <a href="#">Home</a> -->
                                <router-link to="/">Home</router-link>
                            </li>
                            <li><router-link :to="{ name: 'about' }">About us</router-link></li>
                            <li>
                                <router-link :to="{ name: 'directory_listing' }"
                                    >Directory Listing</router-link
                                >
                            </li>
                            <li><router-link :to="{ name: 'vacancies' }">Careers</router-link></li>
                            <li>
                                <a href="https://www.sbgblv.com/page/library" target="_blank"
                                    >Training</a
                                >
                            </li>
                            <li><router-link :to="{ name: 'blogs' }">Blog</router-link></li>

                            <li><router-link :to="{ name: 'policy' }">Policy</router-link></li>
                            <li><router-link :to="{ name: 'media' }">Media</router-link></li>
                        </ul>
                    </div>

                    <div class="col-sm-3">
                        <h3>ACCOUNTS</h3>
                        <ul>
                            <li>
                                <!-- <a href="#">Login user</a> -->
                                <router-link to="/login">Login user</router-link>
                            </li>
                            <!-- <li><a href="/login">Login as TPI/Valuer</a></li> -->
                            <li>
                                <!-- <a href="#">Registration</a> -->
                                <router-link to="/register">Registration</router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'contact' }"
                                    >Complaints/Feedback</router-link
                                >
                            </li>
                        </ul>
                    </div>

                    <div class="col-sm-3">
                        <h3>SOCIAL</h3>
                        <ul>
                            <li>
                                <a href="https://www.facebook.com/scholenberg.3" target="_blank"
                                    ><i class="fa fa-facebook-f"></i
                                ></a>
                            </li>
                            <li>
                                <a href="https://twitter.com/Scholenberg1" target="_blank"
                                    ><i class="fa fa-twitter-square"></i
                                ></a>
                            </li>

                            <li>
                                <a
                                    href="https://www.linkedin.com/in/scholenberg-consulting-2567181b0/"
                                    target="_blank"
                                    ><i class="fa fa-linkedin"></i
                                ></a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/scholenberg123/" target="_blank"
                                    ><i class="fa fa-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    href="https://www.youtube.com/channel/UCT1b9Xy36suK8GNxe2CrPbQ"
                                    target="_blank"
                                    ><i class="fa fa-youtube-play"></i
                                ></a>
                            </li>
                        </ul>
                    </div>

                    <div class="col-sm-3">
                        <h3>SUPPORT</h3>
                        <ul>
                            <li><router-link :to="{ name: 'faq' }">Help & FAQ</router-link></li>
                            <li><router-link :to="{ name: 'contact' }">Contact us</router-link></li>
                            <li>
                                <router-link :to="{ name: 'terms_conditions' }"
                                    >Terms & Conditions</router-link
                                >
                            </li>
                            <!-- <li><a href="#">Complaint/Feedback</a></li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copyright">
            <div class="container">
                <div class="row">
                    <div class="col-sm-8">
                        <p style="font-size: 16px;">
                            All rights reserved &copy;2020 - Design & Development by
                            <span
                                ><a
                                    class="d-inline"
                                    href="https://www.facebook.com/devjimedia"
                                    target="_blank"
                                >
                                    Devji Media & Communications</a
                                ></span
                            >
                        </p>
                    </div>
                    <div class="col-sm-4">
                        <div class="socil-icon footer-icon">
                            <ul class="wow bounceInRight">
                                <li>
                                    <a href="https://www.facebook.com/scholenberg.3" target="_blank"
                                        ><i class="fa fa-facebook-f"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/Scholenberg1" target="_blank"
                                        ><i class="fa fa-twitter-square"></i
                                    ></a>
                                </li>

                                <li>
                                    <a
                                        href="https://www.linkedin.com/in/scholenberg-consulting-2567181b0/"
                                        target="_blank"
                                        ><i class="fa fa-linkedin"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.instagram.com/scholenberg123/"
                                        target="_blank"
                                        ><i class="fa fa-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.youtube.com/channel/UCT1b9Xy36suK8GNxe2CrPbQ"
                                        target="_blank"
                                        ><i class="fa fa-youtube-play"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- padding: 20px 0px;" -->
        <transition name="fade">
            <div class="cookie-confirmation" v-if="displayCookieConfirmation">
                <div class="container">
                    <div class="row" style="padding: 15px 0px;">
                        <div class="col-sm-10" style="color: #ffffff;">
                            <p>
                                We use cookies to enhance our website and improve your experien of
                                it, If you say no to cookies, our website will still function,
                                <br />but it may result in diminished functionality.
                            </p>
                        </div>
                        <div class="col-sm-2">
                            <a
                                @click="acceptCookies"
                                href="javascript:void(0)"
                                style="display: block;padding: 5px;text-align: center; font-size: 0.8em;background-color:#f6c602;color:#8c0526;border-radius: 5px;"
                                >ACCEPT & CLOSE</a
                            >
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showCookieConfirmation: false,
        }
    },
    mounted() {
        setTimeout(() => {
            this.showCookieConfirmation = true
        }, 300)
    },
    methods: {
        acceptCookies() {
            this.showCookieConfirmation = false
            localStorage.setItem("cookies_accepted", true)
        },
    },
    computed: {
        displayCookieConfirmation() {
            const cookiesAccepted = localStorage.getItem("cookies_accepted")
            return !cookiesAccepted && this.showCookieConfirmation
        },
    },
}
</script>

<style lang="scss" scoped>
.fade-enter {
    animation: fade-slide 1s;
}
.fade-leave-active {
    animation: fade-slide 1s reverse;
}

.cookie-confirmation {
    background-color: #7d0010;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    // transform: translate3d(0, 0px, 0);
    // animation: fade-slide 1s;
    z-index: 1000;
}

@keyframes fade-slide {
    from {
        opacity: 0;
        transform: translate3d(0, 500px, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0px, 0);
    }
}
</style>
