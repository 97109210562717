import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import axios from "axios"
import store from "./store/index.js"

//import '@/assets/css/bootstrap.min.css'
import "@/assets/css/animate.css"
import "@/assets/css/style.css"

Vue.component("pagination", require("laravel-vue-pagination"))

import VueSweetalert2 from "vue-sweetalert2"

import Toasted from "vue-toasted"

Vue.use(Toasted, { duration: 2400 })
Vue.use(VueSweetalert2)

import vSelect from "vue-select"

Vue.component("v-select", vSelect)

Vue.config.productionTip = false

import EnlargeableImage from "@diracleo/vue-enlargeable-image"
Vue.use(EnlargeableImage)

axios.defaults.baseURL = "https://mepcindia.com"
axios.defaults.uploadURL = "https://mepcindia.com/admin_profile/"
// axios.defaults.uploadURL = "http://localhost:8000/admin_profile/"
axios.defaults.withCredentials = true
axios.defaults.headers.common["accept"] = "application/json"

console.log("the base url for axios is", axios.defaults.baseURL)

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app")
