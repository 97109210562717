<template>
    <div>
        <Header />
        <!-- Main -->
        <div class="App">
            <router-view></router-view>
        </div>
        <div v-if="showDummyLoader" style="height: 90vh">
            <PageLoader :isLoading="false" />
        </div>
        <Footer />
    </div>
</template>

<script>
// import axios from 'axios';
import Header from "./components/layout/Header.vue"
import Footer from "./components/layout/Footer.vue"
import PageLoader from "./components/Loader.vue"
import { mapActions, mapState } from "vuex"

export default {
    data() {
        return {
            showDummyLoader: true,
        }
    },
    watch: {
        $route(e) {
            this.updateDummyLoader()
        },
    },
    components: { Header, Footer, PageLoader },
    async mounted() {
        this.updateDummyLoader()
        try {
            await this.fetchContactInfo()
            await this.fetchUser()
            if (this.user) {
                await this.fetchPlanInfo()
            }
        } catch (err) {
            console.log("error")
        }
    },
    computed: {
        ...mapState("auth", {
            authenticated: "authenticated",
            isLogged: "authenticated",
        }),
    },

    methods: {
        updateDummyLoader() {
            if (this.$route.name === "landing") {
                this.showDummyLoader = true
                setTimeout(() => {
                    this.showDummyLoader = false
                }, 2500)
            } else {
                setTimeout(() => {
                    this.showDummyLoader = false
                }, 1000)
            }
        },
        ...mapActions("auth", {
            fetchUser: "fetchUser",
            logoutUser: "logout",
        }),
        ...mapActions("plan", {
            fetchPlanInfo: "fetchPlanInfo",
        }),
        ...mapActions("contact", {
            fetchContactInfo: "fetchContactInfo",
        }),
    },
}
</script>

<style scoped>
/* .router-link-exact-active{
  background-color: #8c0428;
  color: #ffff !important;
} */
</style>
