const KEYS = {
    JWT_TOKEN: "JWT_TOKEN",
    USER: "USER",
}
const setItem = (key, value) => localStorage.setItem(key, value)
const getItem = (key) => localStorage.getItem(key)
const removeItem = (key) => localStorage.removeItem(key)

const setToken = (token) => {
    setItem(KEYS.JWT_TOKEN, token)
}
const getToken = () => getItem(KEYS.JWT_TOKEN)
const unsetToken = () => removeItem(KEYS.JWT_TOKEN)

export { KEYS, getToken, setToken, unsetToken, getItem, setItem, removeItem }
