<template>
    <VueLoading
        :active="isLoading"
        :can-cancel="true"
        :is-full-page="true"
        color="#333"
        backgroundColor="rgba(0,0,0,0.3)"
        loader="bars"
    />
</template>

<script>
import VueLoading from "vue-loading-overlay"
import "vue-loading-overlay/dist/vue-loading.css"

export default {
    components: {
        VueLoading,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style></style>
