import request from "../../apis/request"

const contact = {
    namespaced: true,
    state: {
        email: "",
        phone: "",
    },
    mutations: {
        setContactDetails(state, details) {
            state.email = details.email
            state.phone = details.phone
        },
    },
    actions: {
        async fetchContactInfo(context) {
            try {
                const res = await request.get("/contact-info")
                const details = res.data.result
                context.commit("setContactDetails", details)
            } catch (err) {
                console.log("error in fetching contact info")
            }
        },
    },
}

export default contact
