import Vue from "vue"
import VueRouter from "vue-router"
import User from "../apis/User"
import store from "../store/index"
import { getToken, getItem, KEYS } from "../store/localStore"

import Landing from "../components/Landing.vue"
const MaterialTesters = () => import("../components/MaterialTester/MaterialTesters")
const HireTester = () => import("../components/MaterialTester/HireTester")
const Requests = () => import("../components/TestingRequest/Requests")
const Request = () => import("../components/TestingRequest/Request")
const Profile = () => import("../components/Profile/Profile")
const Blog = () => import("../components/Blog/Blog")
const BlogList = () => import("../components/Blog/BlogList")
const BlogCreate = () => import("../components/Blog/BlogCreate")
const Faq = () => import("../components/static/Faq")
const Policy = () => import("../components/static/Policy")
const Media = () => import("../components/static/Media")
const TermsConditions = () => import("../components/static/TermsConditions")

// directory listing...

const DirectoryListing = () => import("../components/Directory/DirectoryListing")
const DirectoryListingCreate = () => import("../components/Directory/DirectoryListingCreate")
const DirectoryCategoryListing = () => import("../components/Directory/DirectoryCategoryListing")
const DirectoryListingSingle = () => import("../components/Directory/DirectoryListingSingle")

Vue.use(VueRouter)

import axios from "axios"

// import landing from '../components/Landing'
// import register from './components/Register'

// utility functions.

let userFetched = false

const isAuthenticated = () => store.state.auth.authenticated

const hasCompletedProfile = () => {
    const user = store.state.auth.user
    let profileCompleted = false
    if (user) {
        profileCompleted = isAuthenticated() && !!user.profile_completed
    }
    console.log("profile completed from router check: ", profileCompleted)
    return profileCompleted
}

// const hasCompletedProfile = () => {
//     const user = User.getUser()
//     let profileCompleted = false
//     if (user) {
//         profileCompleted = User.isLoggedIn() && !!user.profile_completed
//     }
//     console.log("profile completed from router check: ", profileCompleted)
//     return profileCompleted
// }

const routes = [
    {
        path: "/",
        name: "landing",
        component: Landing,
    },
    {
        path: "/faq",
        name: "faq",
        component: Faq,
    },
    {
        path: "/policy",
        name: "policy",
        component: Policy,
    },
    {
        path: "/media",
        name: "media",
        component: Media,
    },
    {
        path: "/terms-conditions",
        name: "terms_conditions",
        component: TermsConditions,
    },
    {
        path: "/register",
        name: "register",
        component: () => import("../components/Register.vue"),
        meta: { guestOnly: true },
    },
    {
        path: "/profile/:id",
        name: "profile",
        component: Profile,
    },
    {
        path: "/materialregister",
        name: "materialregister",
        component: () => import("../components/Materialregis.vue"),
        meta: { guestOnly: true },
    },
    {
        path: "/login",
        name: "login",
        component: () => import("../components/Login.vue"),
        meta: { guestOnly: true },
    },
    {
        path: "/forgot-password",
        name: "forgot_password",
        component: () => import("../components/auth/ForgotPassword.vue"),
        meta: { guestOnly: true },
    },
    {
        path: "/reset-password",
        name: "reset_password",
        component: () => import("../components/auth/ResetPassword.vue"),
        meta: { guestOnly: true },
    },
    {
        path: "/hire-tester/:id",
        name: "hire_tester",
        component: HireTester,
        meta: { authOnly: true },
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("../components/Dashboard.vue"),
        meta: { authOnly: true },

        // beforeEnter:(to,form,next)=>{
        //   axios.get('api/athenticated').then(()=>{
        //     next()
        //   }).catch(()=>{
        //       return next({name: 'login' })
        //   })
        // }
    },

    {
        path: "/pricing",
        name: "pricing",
        component: () => import("../components/Pricing.vue"),
    },

    {
        path: "/categories",
        name: "categories",
        component: () => import("../components/Categories.vue"),
    },

    {
        path: "/vacancies",
        name: "vacancies",
        component: () => import("../components/Vacancies.vue"),
    },

    {
        path: "/privacy-policy",
        name: "privacy_policy",
        component: () => import("../components/PrivacyPolicy.vue"),
    },

    {
        path: "/applyforjob/:id",
        name: "applyforjob",
        component: () => import("../components/Applyforjob.vue"),
    },

    {
        path: "/payment/:id",
        name: "payment",
        component: () => import("../components/Payment.vue"),
        meta: { authOnly: true },
    },

    {
        path: "/surplus",
        name: "surplus",
        component: () => import("../components/Surplus.vue"),

        // beforeEnter:(to,form,next)=>{
        //   axios.get('api/athenticated').then(()=>{
        //     const user = JSON.parse(window.localStorage.getItem('user'));

        //     if(user.role_id == 2){
        //       next()
        //     }
        //     else{

        //       alert('you are not buyer or seller');
        //       return next({name: 'login' })
        //     }

        //   }).catch(()=>{
        //       return next({name: 'login' })
        //   })
        // }
    },

    {
        path: "/my_jobs",
        name: "my_jobs",
        component: () => import("../components/my_jobs.vue"),
    },

    {
        path: "/requests",
        name: "requests",
        component: Requests,
    },
    {
        path: "/requests/:id",
        name: "request",
        component: Request,
    },
    {
        path: "/product-listing",
        name: "product-listing",
        component: () => import("../components/SurplusProducts.vue"),
    },

    {
        path: "/single-product/:id",
        name: "single-product",
        component: () => import("../components/SingleProduct.vue"),
    },

    {
        path: "/search",
        query: {},
        name: "search",
        component: () => import("../components/Search.vue"),
    },

    {
        path: "/list-category/:id",
        name: "list-category",
        component: () => import("../components/Listcategory.vue"),
    },

    {
        path: "/create-job",
        name: "create-job",
        component: () => import("../components/Addjob.vue"),
        meta: { authOnly: true },
    },

    {
        path: "/about",
        name: "about",
        component: () => import("../components/About.vue"),
    },

    {
        path: "/reviews",
        name: "reviews",
        component: () => import("../components/Reviews.vue"),
    },

    {
        path: "/favourites",
        name: "favourites",
        component: () => import("../components/Favourites.vue"),
    },

    {
        path: "/blogs",
        name: "blogs",
        component: BlogList,
    },
    {
        path: "/blogs/:id",
        name: "blog",
        component: Blog,
    },
    {
        path: "/blog/create",
        name: "blog_create",
        component: BlogCreate,
        meta: { authOnly: true },
    },
    {
        path: "/my-products",
        name: "myproducts",
        component: () => import("../components/SurplusProducts.vue"),
        props: {
            myProducts: true,
        },
        meta: { authOnly: true },
    },
    {
        path: "/enquire",
        name: "enquire",
        component: () => import("../components/Enquire.vue"),
        meta: { authOnly: true },
    },

    {
        path: "/material_enquire",
        name: "material_enquire",
        component: () => import("../components/Material_enquire.vue"),
        meta: { authOnly: true },
    },

    {
        path: "/enquire_details/:id",
        name: "enquire_details",
        component: () => import("../components/EnquireDetails.vue"),
        meta: { authOnly: true },
    },

    {
        path: "/contact",
        name: "contact",
        component: () => import("../components/Contact.vue"),
    },

    {
        path: "/training",
        name: "training",
        component: () => import("../components/Trainings.vue"),
    },

    {
        path: "/directory-listing",
        name: "directory_listing",
        component: DirectoryListing,
    },
    {
        path: "/directory-listing/create",
        name: "directory_listing_create",
        component: DirectoryListingCreate,
    },
    {
        path: "/directory-listing/:categorySlug",
        name: "directory_category_listing",
        component: DirectoryCategoryListing,
    },
    {
        path: "/directory-listing/:categorySlug/:id",
        name: "directory_listing_single",
        component: DirectoryListingSingle,
    },

    {
        path: "/industry_insight",
        name: "industry_insight",
        component: () => import("../components/Industry_Insight.vue"),
    },
    {
        path: "*",
        name: "notfound",
        component: () => import("../components/NotFound.vue"),
    },
]

const router = new VueRouter({
    mode: "history",
    base: process.env.baseURL,
    routes,
})

router.afterEach(async (to, from) => {
    await store.dispatch("auth/fetchUser")
})

router.beforeEach(async (to, from, next) => {
    if (to.matched.some((record) => record.meta.authOnly)) {
        try {
            // await store.dispatch("auth/fetchUser")
            console.log("fetching user")
            if (!getToken() || !getItem(KEYS.USER)) {
                window.location.href = "/login"
            }
        } catch (err) {
            console.log("error in fetching user in router", err)
            return next({
                name: "login",
                query: { redirect: to.fullPath },
            })
        }
    } else if (!userFetched) {
        // await User.updateUserFromDB()
        try {
            console.log("start fetching user for router")
            // await store.dispatch("auth/fetchUser")
        } catch (err) {
            console.log("error in fetching user in router", err)
        }
        userFetched = true
    }

    if (to.matched.some((record) => record.meta.authOnly)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        console.log("auth only check running")

        if (!isAuthenticated()) {
            console.log("not logged in")

            return next({
                name: "login",
                query: { redirect: to.fullPath },
            })
        } else if (
            to.path !== "/register" &&
            // to.path !== "/materialregister" &&
            !hasCompletedProfile()
        ) {
            console.log("is logged in but not completed the profile", from, to)

            return next({
                name: "register",
                query: { redirect: to.fullPath },
            })
        } else {
            console.log("OKAY WITH EVERYTHING")
            return next()
        }
    } else if (to.matched.some((record) => record.meta.guestOnly)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        console.log("guest only check running")
        if (hasCompletedProfile()) {
            console.log("has completed profile")
            return next({
                name: "dashboard",
                query: { redirect: to.fullPath },
            })
        } else if (
            to.path !== "/register" &&
            // to.path !== "/materialregister" &&
            isAuthenticated()
        ) {
            console.log(
                "is logged but not completed profile (redirecting to register). from route - ",
                from,
                to
            )
            // logged but not completed the profile
            return next({
                name: "register",
                query: { redirect: to.fullPath },
            })
        } else {
            console.log("OK WITH EVERYTHING")
            return next()
        }
    } else {
        return next() // make sure to always call next()!
    }
})

export default router
