import axios from "axios";

let Api = axios.create({
//   baseURL: "http://localhost:8080/api"
//   baseURL: "http://103.212.120.205:8080/api"

 baseURL: "https://mepcindia.com/api",
 withCredentials: true,
 
});

Api.defaults.withCredentials = true;

export default Api;