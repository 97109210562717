<template>
    <div class="keyword-input-container">
        <div
            class="keyword-input-backdrop"
            v-show="showSuggestions"
            @click="showSuggestions = false"
        ></div>
        <input
            type="text"
            :class="['keyword-input', { 'inside-filter-sidebar': insideFilterSidebar }, { active }]"
            :placeholder="placeholder"
            :value="keywordText"
            @input="$emit('updateKeywordText', $event.target.value)"
            @click="keywordSuggestions.length > 0 && (showSuggestions = true)"
        />
        <div
            :class="['keyword-input-suggestions', { 'inside-filter-sidebar': insideFilterSidebar }]"
            v-show="showSuggestions"
        >
            <div
                class="keyword-input-suggestion-item"
                v-for="(suggestion, i) of keywordSuggestions"
                :key="i"
                @click="selectSuggestion(suggestion)"
            >
                {{ suggestion }}
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"

export default {
    model: {
        prop: "keywordText",
        event: "updateKeywordText",
    },
    props: {
        placeholder: {
            type: String,
            default: "Enter Keyword",
        },
        keywordText: {
            type: String,
            default: "",
        },
        insideFilterSidebar: {
            type: Boolean,
            default: false,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            keywordSuggestions: [],
            searchTimeout: null,
            searchTimer: 300,
            showSuggestions: false,
        }
    },
    watch: {
        async keywordText(e) {
            console.log("keyword text has changed")
            if (this.keywordSuggestions.includes(e)) {
                return
            }
            window.clearTimeout(this.searchTimeout)
            this.searchTimeout = window.setTimeout(async () => {
                if (e.length > 0) {
                    try {
                        const res = await axios.get(`/api/surplus/keyword-suggestions?keyword=${e}`)
                        this.keywordSuggestions = res.data.result
                        if (this.keywordSuggestions.length > 0) {
                            this.showSuggestions = true
                        } else {
                            this.showSuggestions = false
                        }
                    } catch (err) {
                        console.log("Erorr in loading keyword sugggestions")
                        this.showSuggestions = false
                    }
                } else {
                    this.showSuggestions = false
                }
            }, this.searchTimer)
        },
    },
    methods: {
        selectSuggestion(suggestion) {
            this.showSuggestions = false
            this.$emit("updateKeywordText", suggestion)
        },
    },
}
</script>

<style lang="scss" scoped>
$input-bg: rgb(245, 245, 245);
$active-input-bg: rgb(250, 255, 217);

.keyword-input-container {
    position: relative;
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    margin: 15px 0;
    margin-top: 5px;

    .keyword-input-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
    }
    .keyword-input {
        width: 100%;
        padding: 10px;
        outline: none;
        font-size: 0.9em;
        transition: 0.3s;

        &.inside-filter-sidebar {
            border: none;
            font-size: 1em;
            color: #333;
            padding: 12px 15px;
            height: 100%;
            background: $input-bg;
            transform: translateY(5px);
        }
        &.active {
            background: $active-input-bg;
        }
    }
    .keyword-input-suggestions {
        width: 400px;
        background-color: white;
        position: absolute;
        left: 0;
        top: 50px;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0 0 5px 0 rgba(65, 41, 41, 0.4);
        padding: 5px;
        font-size: 0.9em;

        &.inside-filter-sidebar {
            width: 300px;
        }

        .keyword-input-suggestion-item {
            padding: 5px 15px;
            width: 100%;
            cursor: pointer;
            color: #444;
            text-overflow: ellipsis; /* IE, Safari (WebKit) */
            overflow: hidden; /* don't show excess chars */
            white-space: nowrap;
            border-radius: 5px;
            transition: 0.3s;

            &:hover {
                background-color: rgb(254, 213, 138);
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .keyword-input-container {
        .keyword-input-suggestions {
            width: 300px;
        }
    }
}
</style>
