<template>
    <img :src="src" :alt="alt" :onerror="fallbackImage" class="img-fluid" />
</template>

<script>
export default {
    props: {
        src: {
            type: String,
            default: "",
        },
        alt: {
            type: String,
            default: "",
        },
    },
    computed: {
        fallbackImage() {
            // let code = `this.src = '${process.env.VUE_APP_FALLBACK_IMAGE_SRC}';`
            let code = `this.src = 'http://mepcindia.com/public/admin_profile/notfound.jpg';`
            code += "this.onerror = null"
            // return ""
            return code
        },
    },
}
</script>

<style></style>
