/* For the plans purchased by the user */
import request from "../../apis/request"
import Vue from "vue"

const plan = {
    namespaced: true,
    state: {
        availablePlans: {},
        currentPlan: null,
    },
    getters: {
        getUpgradePrice: (state) => (plan) => {
            if (!state.currentPlan || !plan) return
            const upgradePlan = state.currentPlan.upgrade.find((item) => item.plan_id === plan.id)
            return upgradePlan.price
        },
        allowUpgrade: (state) => (plan) => {
            if (!state.currentPlan || !plan) return

            if (state.currentPlan.plan.id == 3) {
                return false
            }

            if (state.currentPlan.plan.price < plan.price) {
                return true
            }

            if (!plan.plans_name) {
                return true
            }

            return false
        },
        isFreePlan(state) {
            return state.currentPlan.plan.plans_name === "FREE"
        },
    },
    mutations: {
        setCurrentPlan(state, currentPlan) {
            if (currentPlan) {
                state.currentPlan = currentPlan
            }
        },
        setData(state, { key, value }) {
            state[key] = value
        },
        unsetData(state) {
            state.availablePlans = {}
            state.currentPlan = {}
        },
    },
    actions: {
        async fetchAvailablePlans(context) {
            const res = await request.get("/plans/available")
            const data = res.data.result
            context.commit("setData", {
                key: "availablePlans",
                value: data,
            })
            console.log("plans fetched", data)
        },
        async fetchCurrentPlan(context) {
            try {
                const res = await request.get("/plans/user")
                const data = res.data.result
                context.commit("setCurrentPlan", data)
            } catch (err) {
                Vue.$toasted.error(err.message || err.response.data.message)
            }
        },
        async fetchPlanInfo(context) {
            await context.dispatch("fetchCurrentPlan")
            await context.dispatch("fetchAvailablePlans")
        },
        async purchasePlan(context, { planId }) {
            try {
                const res = await request.post("/plans/purchase", {
                    plan_id: planId,
                })
                const data = res.data.result
                // context.commit("setCurrentPlan", data)
                context.dispatch("fetchCurrentPlan")
                Vue.$toasted.success(data.message)
            } catch (err) {
                Vue.$toasted.error(err.message || err.response.data.message)
            }
        },
        async upgradePlan(context, { planId }) {
            try {
                const res = await request.post("/plans/upgrade", {
                    plan_id: planId,
                })
                const data = res.data.result
                // context.commit("setCurrentPlan", data)
                context.dispatch("fetchCurrentPlan")
                Vue.$toasted.success(data.message)
            } catch (err) {
                Vue.$toasted.error(err.message || err.response.data.message)
            }
        },
    },
}

export default plan
