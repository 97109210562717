import axios from "axios"
import { getToken } from "../store/localStore"

const baseURL = "https://mepcindia.com"
// const baseURL = "http://localhost:8000"

const prefix = baseURL + "/api"

console.log("PREFIX FOR REQUEST JS", prefix)

const request = {
    get: (url, headers = {}) => {
        return axios.get(prefix + url, {
            headers: {
                accept: "application/json",
                authorization: "Bearer " + getToken(),
                ...headers,
            },
        })
    },
    post: (url, body = {}, headers = {}) => {
        return axios.post(prefix + url, body, {
            headers: {
                accept: "application/json",
                authorization: "Bearer " + getToken(),
                ...headers,
            },
        })
    },

    put: (url, body = {}, headers = {}) => {
        return axios.put(prefix + url, body, {
            headers: {
                accept: "application/json",
                authorization: "Bearer " + getToken(),
                ...headers,
            },
        })
    },
    patch: (url, body = {}, headers = {}) => {
        return axios.patch(prefix + url, body, {
            headers: {
                accept: "application/json",
                authorization: "Bearer " + getToken(),
                ...headers,
            },
        })
    },

    delete: (url, body = {}, headers = {}) => {
        return axios.delete(prefix + url, body, {
            headers: {
                accept: "application/json",
                authorization: "Bearer " + getToken(),
                ...headers,
            },
        })
    },
}

// axios.interceptors.response.use((res, res2) => {
//     console.log("interceptor response", res.status)
//     if (res.status == 401) {
// unauthorized then force reload so that the user is fully logged out
//         window.location.reload()
//     }
//     return res
// })

export default request
