<template>
    <div class="vue-template">
        <div style="height: 200vh" v-if="showDummyScreen"></div>
        <Loader :isLoading="isLoading" />
        <div class="slide-section">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <h2>WELCOME TO MEPCINDIA.COM</h2>
                        <p>
                            Find high quality Pre-owned Construction material near you for EPC and
                            MEP Projects.
                        </p>
                        <h3>Save Cost | Easy Sourcing | Ready-Stock</h3>
                        <div class="from-box-slid mx-md-4">
                            <KeywordInput
                                placeholder="Enter Keyword (i.e. Pipe, cable, cement, tiles, etc.)"
                                v-model="searchkey"
                            />
                            <form class="row" style="font-size: 0.8em;">
                                <select v-model="searchcat" class="col-sm">
                                    <option disabled value="">Select Category</option>
                                    <option
                                        v-for="user in category"
                                        :key="user.id"
                                        :value="user.id"
                                        >{{ user.name }}</option
                                    >
                                    <!-- <option>Select Category</option> -->
                                </select>
                                <select class="col-sm" v-model="state">
                                    <option disabled value="">Select State*</option>
                                    <option
                                        v-for="user in all_state"
                                        :key="user.state_code"
                                        :value="user.state_code"
                                        >{{ user.state_name }}</option
                                    >
                                </select>

                                <select v-model="city" class="col-sm" v-if="state">
                                    <option disabled value="">Select City*</option>
                                    <option
                                        v-for="user in all_city"
                                        :key="user.city_code"
                                        :value="user.city_code"
                                        >{{ user.city_name }}</option
                                    >
                                </select>

                                <!-- <input
                                    class="col-sm"
                                    type="text"
                                    name="zipcode"
                                    v-model="searchzip"
                                    placeholder="Please add pin code"
                                    required
                                /> -->
                                <!-- <span
                                    class="status small col-sm-2"
                                    id="zipcode-status"
                                ></span> -->
                                <button class="col-sm" type="submit" @click="search">
                                    <i class="fa fa-search"></i> SEARCH
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="product-ads py-5">
            <div class="container">
                <h2>Surplus Pre-owned Inventory</h2>
                <p>
                    Add or buy surplus products from our different categories.
                </p>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="tab-content" id="pills-tabContent">
                            <div
                                class="tab-pane fade show active"
                                id="pills-home"
                                role="tabpanel"
                                aria-labelledby="pills-home-tab"
                            >
                                <div class="row">
                                    <div
                                        class="col-sm-6 col-md-3"
                                        v-for="products in product"
                                        :key="products.id"
                                    >
                                        <div class="product-box" v-if="products">
                                            <div class="" style="position: relative">
                                                <ImageItem
                                                    v-if="products.image"
                                                    :src="imgsrc + products.image"
                                                />
                                                <ImageItem
                                                    v-else
                                                    src="http://mepcindia.com/public/admin_profile/notfound.jpg"
                                                />
                                                <div class="images-hover h-100">
                                                    <router-link
                                                        class="view-product nav-link post-btn"
                                                        :to="{
                                                            name: 'single-product',
                                                            params: {
                                                                id: products.id,
                                                            },
                                                        }"
                                                        >VIEW</router-link
                                                    >
                                                </div>
                                            </div>
                                            <div class="m-2 mb-3">
                                                <h3
                                                    style="font-size: 17px;"
                                                    v-if="products.title.length < 20"
                                                >
                                                    {{ products.title }}
                                                </h3>
                                                <h3
                                                    style="font-size: 17px;"
                                                    v-if="products.title.length >= 20"
                                                >
                                                    {{ products.title.substring(0, 20) + ".." }}
                                                </h3>

                                                <h4
                                                    style="font-size: 14px;"
                                                    v-if="products.category.name.length < 20"
                                                >
                                                    {{ products.category.name }}

                                                    <span style="font-size: 16px;"
                                                        >QTY: {{ products.quenty }}</span
                                                    >
                                                </h4>

                                                <h4
                                                    style="font-size: 14px;"
                                                    v-if="products.category.name.length >= 20"
                                                >
                                                    {{
                                                        products.category.name.substring(0, 20) +
                                                            ".."
                                                    }}

                                                    <span style="font-size: 16px;"
                                                        >QTY: {{ products.quenty }}</span
                                                    >
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="tab-pane fade"
                                id="pills-profile"
                                role="tabpanel"
                                aria-labelledby="pills-profile-tab"
                            >
                                <div class="row"></div>
                            </div>
                            <div
                                class="tab-pane fade"
                                id="pills-contact"
                                role="tabpanel"
                                aria-labelledby="pills-contact-tab"
                            >
                                <div class="row"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <a href="#" class="view-product">VIEW MORE...</a> -->
                <router-link class="view-product nav-link post-btn" to="product-listing"
                    >VIEW MORE...</router-link
                >
            </div>
        </div>

        <div class="are-you">
            <div class="row">
                <div class="col-sm-6">
                    <div class="are-yellow pt-4 pb-3" style="background-color:#8c0526;">
                        <h4>Facing material shortage at site?</h4>
                        <h4>
                            Need Faster delivery with Best Quality and Cheaper rates?

                            <!-- "<span>SCHOLANBERG</span>"? -->
                        </h4>
                        <p>Post your shortages here and get the supplies you need.</p>
                        <router-link to="/surplus" style="background-color:#f6c602;color:#8c0526">
                            POST HERE</router-link
                        >
                    </div>
                </div>
                <div class="col-sm-6">
                    <ImageItem class="img-fluid h-100" src="/images/shortage.jpg" />
                </div>
            </div>
        </div>

        <div class="populer-cat">
            <div class="container">
                <h2>POPULAR CATEGORIES</h2>
                <p class="px-3 py-2">
                    Now post/view surplus pre-owned inventory here. One-stop solution to the
                    pre-owned construction material. <br />Authenticate the material via listed
                    material inspectors Reduce your overheads.
                </p>
                <div class="row">
                    <div
                        class="col-sm-3 mb-3"
                        v-for="category in homecategory.slice(0, 8)"
                        :key="category.id"
                        style="cursor: pointer"
                    >
                        <div
                            class="categorie_box"
                            style="border-top: 3px solid;"
                            v-bind:style="{ color: category.color }"
                            @click="
                                $router.push({
                                    name: 'product-listing',
                                    query: { page: 1, category: category.id },
                                })
                            "
                        >
                            <div
                                class="categorie_image"
                                v-bind:style="{ background: category.color }"
                            >
                                <ImageItem :src="imgsrc + category.img" />
                            </div>
                            <h4>{{ category.name }}</h4>
                            <p>
                                {{ category.description }}
                            </p>
                        </div>
                    </div>
                </div>
                <!-- <a href="#">VIEW ALL CATEGORIES</a> -->
                <router-link class="nav-link" to="/categories">VIEW ALL CATEGORIES</router-link>
            </div>
        </div>

        <div class="are-you">
            <div class="row">
                <div class="col-sm-6">
                    <div class="are-yellow">
                        <h2 style="color: #8d0529;">
                            3 Easy Steps to liquidate Surplus
                        </h2>
                        <!--<h3 style="color: #8d0529;">
              FOR POSTING YOUR PRODUCTS ON<br />
              <span>"SCHOLANBERG"?</span>
            </h3>-->
                        <h4 style="color: #8d0529;">
                            Sign Up
                        </h4>
                        <br />
                        <h5 style="color: #8d0529;">
                            Post your Pre-owned inventory
                        </h5>
                        <h5 style="color: #8d0529;">Get Unlimited Leads</h5>
                        <!-- <a href="#">POST YOUR SURPLUS</a> -->
                        <router-link class="my-2 nav-link post-btn" to="surplus"
                            >POST YOUR SURPLUS</router-link
                        >
                    </div>
                </div>
                <div class="col-sm-6"><ImageItem src="/images/are-you1.jpg" /></div>
            </div>
        </div>

        <div class="why-choose">
            <div class="container">
                <h2>WHY CHOOSE MEPCINDIA.COM</h2>
                <p>
                    We are India’s No.1 and Fastest Growing EPC inventory Liquidation portal with
                    commitment to liquidate Worth INR 58.3 Billion of Pre-owned inventory by 2030 to
                    facilitate the construction industry with easing the cash bottlenecks, reduced
                    sourcing overheads, Best Quality Pre-owned material, Various Trust measures as
                    Listing of material inspectors and other aligned services.
                </p>
                <div class="row">
                    <div class="col-6 col-sm">
                        <ImageItem src="/images/why5.jpg" />
                        <h3>25+</h3>
                        <p>Years' Experience</p>
                    </div>
                    <div class="col-6 col-sm">
                        <ImageItem src="/images/why1.jpg" />
                        <h3>{{ homecategory.length }}+</h3>
                        <p>Categories</p>
                    </div>
                    <div class="col-6 col-sm">
                        <ImageItem src="/images/why2.jpg" />
                        <h3>500+</h3>
                        <p>Locations</p>
                    </div>
                    <div class="col-6 col-sm">
                        <ImageItem src="/images/why3.jpg" />
                        <h3>1500+</h3>
                        <p>Active Members</p>
                    </div>
                    <div class="col-6 col-sm">
                        <ImageItem src="/images/why4.jpg" />
                        <h3>₹100,000,000 +</h3>
                        <p>Inventory Liquidation</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="news-letter">
            <div class="container">
                <h2>
                    SUBSCRIBE TO OUR NEWSLETTER AND GET A TRAINING COURSE FREE!
                </h2>
                <p>
                    Stay connected with us for industry news and insights. Plus stay upskilled with
                    our Life changing courses.
                </p>
                <div class="row">
                    <div class="col-sm-8 offset-2">
                        <div :class="['news-box', 'mb-3', 'py-3', { 'bg-success': subscribed }]">
                            <div v-if="subscribed" class="text-white">
                                You have subscribed to our newsletter successfully!
                            </div>
                            <form @submit.prevent="register" v-else>
                                <input
                                    type="text"
                                    name="email"
                                    v-model="email"
                                    placeholder="Enter Your Email Address.."
                                />

                                <button type="submit" class="mt-2" v-if="processingForm">
                                    Please wait...
                                </button>
                                <button type="submit" class="mt-2" v-else>
                                    SUBSCRIBE <i class="fa fa-location-arrow"></i>
                                </button>
                            </form>
                        </div>
                    </div>

                    <div class="col-12">
                        “Once in lifetime opportunity...!! Don’t miss.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="https://ajax.googleapis.com/ajax/libs/jquery/2.1.1/jquery.min.js"></script>

<script>
import axios from "axios"
import "vue-select/src/scss/vue-select.scss"
import vSelect from "vue-select"
import Loader from "./Loader.vue"
import KeywordInput from "./utils/KeywordInput.vue"
import request from "../apis/request"
import { mapState, mapActions } from "vuex"
import scrollTop from "./utils/scrollTop"
import ImageItem from "./utils/ImageItem"

export default {
    name: "landing",
    components: {
        vSelect,
        Loader,
        KeywordInput,
        ImageItem,
    },

    data() {
        return {
            showDummyScreen: true,
            isLoading: false,
            category: null,
            product: null,
            searchcat: "",
            searchkey: "",
            searchzip: "",
            processingForm: false,
            homecategory: null,
            listproduct: null,
            email: "",
            type: "",
            all_state: null,
            state: "",
            city: "",
            all_city: null,
            state_show: false,
            city_show: false,
            pincode_show: false,
            action: "/api/category",
            subscribed: false,

            //   imgsrc: "http://localhost/backend/public/admin_profile/",
            // imgsrc: "http://103.212.120.205/~dev/public/admin_profile/",
            imgsrc: axios.defaults.uploadURL,
        }
    },

    computed: {
        ...mapState("auth", {
            user: "user",
        }),
    },
    methods: {
        ...mapActions("auth", {
            fetchUser: "fetchUser",
        }),
        get_type() {
            //   console.log(this.type);
            if (this.type == "state") {
                this.state_show = true
            } else {
                this.state_show = false
            }
            if (this.type == "city") {
                this.city_show = true
            } else {
                this.city_show = false
            }
            //   if(this.type == "pincode"){
            //       this.pincode_show = true;
            //   }else{
            //       this.pincode_show = false;
            //   }
        },

        search: function(e) {
            e.preventDefault()

            if (!this.state || !this.city) {
                return this.$toasted.error("Please select State and City to search.")
            }
            const query = {}
            if (this.searchzip) {
                query.pincode = this.searchzip
            }
            if (this.searchkey) {
                query.keyword = this.searchkey
            }
            if (this.searchcat) {
                query.category = this.searchcat
            }
            if (this.state) {
                query.state = this.state
            }
            if (this.city) {
                query.city = this.city
            }

            this.$router.push({
                path: "/search",
                query,
            })
        },
        async loadFilterOptions() {
            try {
                const res = await axios.get("/api/surplus/filter-options?all=true")
                const filterOptions = res.data.filterOptions
                this.category = filterOptions.categoryOptions
                // this.all_city = filterOptions.cityOptions
                this.all_state = filterOptions.stateOptions
            } catch (err) {
                console.log("error in loading filter options")
            }
        },

        async fetchCities() {
            const res = await request.get("/city/" + this.state)
            this.all_city = res.data
        },

        register: function(e) {
            e.preventDefault()

            if (this.processingForm) return

            if (!this.email) {
                return this.$toasted.error("Please enter your email address.")
            }
            if (!this.validateEmail()) {
                return this.$toasted.error("Email address is not valid.")
            }
            this.processingForm = true
            this.ress = {}
            request
                .post("/subscribe", {
                    email: this.email,
                })
                .then(async (res) => {
                    this.processingForm = false
                    this.subscribed = true
                    if (res.status === 200) {
                        this.$toasted.success("Thank you for Subscribing", {})
                    } else {
                        throw new Error(res)
                    }
                })
                .catch((err) => {
                    //alert('err');
                    this.processingForm = false
                    this.$toasted.error(
                        (err.response.data && err.response.data.message) ||
                            "Could not subscribe. Try again later.",
                        {}
                    )

                    // if (res.response.status === 422) {
                    //     this.ress = res.response.data.errors || {}
                    // }
                    // return res
                })
        },
        validateEmail: function() {
            const regMail = /^([_a-zA-Z0-9-]+)(\.[_a-zA-Z0-9-]+)*@([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,3})$/
            let res = regMail.test(this.email)
            return res
        },
    },
    watch: {
        state(e) {
            this.fetchCities()
        },
    },
    async created() {
        this.isLoading = true
        try {
            let response = await axios.get("/api/categories")
            this.category = response.data

            //  axios
            //    .get("/api/product")

            //    .then((response) => (this.product = response.data));

            response = await axios.get("/api/homepage")
            this.homecategory = response.data

            response = await axios.get("/api/listproducthome")
            this.product = response.data.result

            // response = await axios.get("/api/state")
            // this.all_state = response.data

            // response = await axios.get("/api/all_city")
            // this.all_city = response.data
            await this.loadFilterOptions()
        } catch (err) {
            console.log("Error in loading products")
        }
        this.isLoading = false
    },

    mounted() {
        scrollTop()
        setTimeout(() => {
            this.showDummyScreen = false
        }, 1000)
    },
    beforeDestroy() {
        // this.isLoading = true
        console.log("home page unmounting")
    },
}
</script>

<style type="text/css">
.product-box img {
    height: 240px;
}
</style>
